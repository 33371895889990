// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-mdx": () => import("./../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-education-tsx": () => import("./../src/pages/education.tsx" /* webpackChunkName: "component---src-pages-education-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-skills-mdx": () => import("./../src/pages/skills.mdx" /* webpackChunkName: "component---src-pages-skills-mdx" */),
  "component---src-pages-social-mdx": () => import("./../src/pages/social.mdx" /* webpackChunkName: "component---src-pages-social-mdx" */),
  "component---src-pages-uses-mdx": () => import("./../src/pages/uses.mdx" /* webpackChunkName: "component---src-pages-uses-mdx" */),
  "component---src-pages-work-tsx": () => import("./../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

